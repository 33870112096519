import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import SiteMetadata from 'src/components/SiteMetadata.jsx';
import Layout from 'src/layouts/Layout';
import MAHHLogo from 'src/images/mahh-logo-512.png';
// import AboutCareer from 'src/images/about-career.jpg';
import './about.scss';

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import Button from '../components/Button';

function About(props) {
  const { about, employees, clients } = props.data;
  const employeesList = employees.employees;
  const clientNodes = clients?.clients;

  const employeeSwiperParams = {
    spaceBetween: 20,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 4,
      },
      640: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 2,
      },
    },
  };

  const clientsSwiperParams = {
    spaceBetween: 30,
    slidesPerView: 5,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  return (
    <Layout>
      <SiteMetadata
        title='About'
        description={about?.description?.description}
        image={MAHHLogo}
      />
      <div className='about-container'>
        <Fade bottom>
          <div className='mahh-section-title-wrapper'>
            <div className='mahh-red-shadow'></div>
            <h2 className='mahh-section-title'>OUR WORKSPACE</h2>
          </div>
        </Fade>
        {/* Picture and ABOUT APE PLUS content */}
        <div className='about-main row-helper'>
          <Fade bottom>
            <div className='about-main-left flex-center-center col-sm-12 col-md-6'>
              <Img
                fluid={about?.image?.localFile?.childImageSharp?.fluid}
                alt='studio'
                style={{ width: '100%' }}
              />
            </div>
          </Fade>
          <div className='about-main-right col-sm-12 col-md-6 mt-24'>
            <Fade bottom>
              <div className='mahh-section-title-wrapper mb-24'>
                <div className='mahh-red-shadow' style={{ width: '40%' }}></div>
                <h2 className='mahh-section-title text-center mb-4'>
                  ABOUT APE PLUS
                </h2>
              </div>
              <p className='about-paragraph'>
                {about?.description?.description}
              </p>
            </Fade>
          </div>
        </div>

        {/* Our clients */}
        <div className='clients-wrapper'>
          <h2 className='font-bold mb-4 text-center text-sm text-white'>
            Our Clients
          </h2>
          <Fade bottom>
            <Swiper {...clientsSwiperParams}>
              {clientNodes?.map((client, index) => {
                return (
                  <div className='logo-client-container' key={index}>
                    <Img
                      className='image-logo-client'
                      fluid={client?.image?.localFile?.childImageSharp?.fluid}
                      alt={client.name}
                    />
                  </div>
                );
              })}
            </Swiper>
          </Fade>
        </div>

        {/* The crews */}
        <div className='the-crews-container mt-16'>
          <Fade bottom>
            <div className='mahh-section-title-wrapper'>
              <div className='mahh-red-shadow-center'></div>
              <h2 className='mahh-section-title text-center mb-4'>THE CREWS</h2>
            </div>
            <Swiper {...employeeSwiperParams}>
              {employeesList?.map((employee, index) => {
                return (
                  <div key={index}>
                    <Img
                      className='image-logo-client'
                      fluid={employee?.image?.localFile?.childImageSharp?.fluid}
                      alt={employee.name}
                    />
                    {/* <img src={crew} alt='crew image' /> */}
                    <h5 className='text-base md:text-lg font-bold'>
                      {employee?.name}
                    </h5>
                    <p className='text-xs md:text-sm'>{employee?.jobTitle}</p>
                  </div>
                );
              })}
            </Swiper>
            {/* Career banner */}
            <div className='career-banner-background'>
              <div className='career-banner-content-container'>
                <h3 className='text-xl lg:text-4xl text-white font-bold'>
                  Wanna Become
                </h3>
                <h3 className='text-xl lg:text-4xl text-white font-bold'>
                  One of us?
                </h3>
                <Link to={'/career'}>
                  <Button className='career-banner-button mt-4'>CAREERS</Button>
                </Link>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query AboutQuery {
    about: contentfulAbout {
      description {
        description
      }
      image {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    employees: contentfulEmployeeList {
      employees {
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        id
        jobTitle
        name
      }
    }
    clients: contentfulClientList {
      clients {
        name
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default About;
